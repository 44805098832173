<template>
  <transition name="viewer-fade">
    <div
      tabindex="-1"
      ref="el-image-viewer__wrapper"
      class="el-image-viewer__wrapper"
      :style="{ 'z-index': viewerZIndex }"
    >
      <div class="el-image-viewer__mask" @click.self="handleMaskClick"></div>
      <!-- CLOSE -->
      <span class="el-image-viewer__btn btn_hover el-image-viewer__fullScreen" @click="toggleMode">
        <i :class="mode.icon"></i>
      </span>
      <span class="el-image-viewer__btn btn_hover el-image-viewer__close" @click="hide">
        <i class="el-icon-close"></i>
      </span>
      <span class="img-name-big">
        {{ nameList[index] }}
      </span>
      <!-- ARROW -->
      <template v-if="!isSingle">
        <span
          class="el-image-viewer__btn btn_hover el-image-viewer__prev"
          :class="{ 'is-disabled': !infinite && isFirst }"
          @click="prev"
        >
          <i class="el-icon-back" />
        </span>
        <span
          class="el-image-viewer__btn btn_hover el-image-viewer__next"
          :class="{ 'is-disabled': !infinite && isLast }"
          @click="next"
        >
          <i class="el-icon-right" />
        </span>
      </template>
      <!-- IMG LIST -->
      <template v-if="!isSingle">
        <div class="small-img-box">
          <el-tabs style="width: 100%" type="card">
            <el-tab-pane v-for="(url, index2) in urlList" :key="index2">
              <div slot="label">
                <el-tooltip class="item" effect="dark" :content="nameList[index2]" placement="top">
                  <img
                    class="small_img"
                    @click="handleClickSmallImg(index2)"
                    :class="index2 === index ? 'select_small_img' : ''"
                    :src="buildUrl(url)"
                  />
                </el-tooltip>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </template>
      <!-- ACTIONS -->
      <div class="el-image-viewer__btn el-image-viewer__actions" :class="!isSingle ? 'el-image-viewer__actions2' : ''">
        <div class="el-image-viewer__actions__inner">
          <i class="el-icon-zoom-out btn_hover" @click="handleActions('zoomOut')"></i>
          <span class="ml10 mr10 img_scale">{{ (transform.scale * 100).toFixed(0) }}%</span>
          <i class="el-icon-zoom-in btn_hover" @click="handleActions('zoomIn')"></i>
          <!-- <i class="el-image-viewer__actions__divider"></i> -->
          <el-divider direction="vertical"></el-divider>
          <!-- <i :class="mode.icon" @click="toggleMode"></i> -->
          <!-- <i class="el-image-viewer__actions__divider"></i> -->
          <i
            class="el-icon-refresh-left btn_hover mr10"
            @click="handleActions('anticlocelise')"
          ></i>
          <i
            class="el-icon-refresh-right btn_hover"
            @click="handleActions('clocelise')"
          ></i>
          <el-divider direction="vertical"></el-divider>
          <el-link type="primary" icon="el-icon-download" @click="downloadImg">下载</el-link>
        </div>
      </div>
      <!-- CANVAS -->
      <div class="el-image-viewer__canvas">
        <img
          v-for="(url, i) in urlList"
          v-if="i === index"
          ref="img"
          class="el-image-viewer__img"
          :key="url"
          :src="buildUrl(currentImg)"
          :style="imgStyle"
          @load="handleImgLoad"
          @error="handleImgError"
          @mousedown="handleMouseDown"
        />
      </div>
    </div>
  </transition>
</template>
<script>
import { on, off } from 'element-ui/src/utils/dom'
import { rafThrottle, isFirefox } from 'element-ui/src/utils/util'
import { PopupManager } from 'element-ui/src/utils/popup'
const Mode = {
  CONTAIN: {
    name: 'contain',
    icon: 'el-icon-full-screen'
  },
  ORIGINAL: {
    name: 'original',
    icon: 'el-icon-c-scale-to-original'
  }
}
const mousewheelEventName = isFirefox() ? 'DOMMouseScroll' : 'mousewheel'
import { updateMixin } from "@/mixins/global"
export default {
  mixins: [updateMixin],
  name: 'elImageViewer',
  props: {
    imgList: {
      type: Array,
      default: () => []
    },
    zIndex: {
      type: Number,
      default: 2000
    },
    onSwitch: {
      type: Function,
      default: () => { }
    },
    onClose: {
      type: Function,
      default: () => { }
    },
    initialIndex: {
      type: Number,
      default: 0
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    maskClosable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      index: this.initialIndex,
      isShow: false,
      infinite: true,
      loading: false,
      mode: Mode.CONTAIN,
      transform: {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false
      }
    }
  },
  computed: {
    nameList() { // 图片名称
      return this.imgList.map(item => {
        return item.name || ''
      })
    },
    urlList() { // 图片url
      return this.imgList.map(item => {
        if (this.validatenull(item.url)) {
          return item
        }
        return item.url
      })
    },
    isSingle() {
      return this.urlList.length <= 1
    },
    isFirst() {
      return this.index === 0
    },
    isLast() {
      return this.index === this.urlList.length - 1
    },
    currentImg() {
      return this.urlList[this.index]
    },
    imgStyle() {
      const { scale, deg, offsetX, offsetY, enableTransition } = this.transform
      const style = {
        transform: `scale(${scale}) rotate(${deg}deg)`,
        transition: enableTransition ? 'transform .3s' : '',
        'margin-left': `${offsetX}px`,
        'margin-top': `${offsetY}px`
      }
      if (this.mode === Mode.CONTAIN) {
        style.maxWidth = style.maxHeight = '100%'
      }
      return style
    },
    viewerZIndex() {
      const nextZIndex = PopupManager.nextZIndex()
      return this.zIndex > nextZIndex ? this.zIndex : nextZIndex
    }
  },
  watch: {
    index: {
      handler: function(val) {
        this.reset()
        this.onSwitch(val)
      }
    },
    currentImg(val) {
      this.$nextTick(_ => {
        const $img = this.$refs.img[0]
        if (!$img.complete) {
          this.loading = true
        }
      })
    }
  },
  methods: {
    buildUrl(url) {
      if(url.indexOf('data:image/') === -1) {
        // url += '?x-oss-process=image/resize,p_60'
        return url
      }
      return url
    },
    downloadImage(imgsrc, name = 'img') {
      // 下载图片地址和图片名
      var image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous")
      image.onload = function() {
        var canvas = document.createElement("canvas")
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext("2d")
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL("image/png") // 得到图片的base64编码数据
        var a = document.createElement("a") // 生成一个a元素
        var event = new MouseEvent("click") // 创建一个单击事件
        a.download = name // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = imgsrc
    },
    downloadImg() {
      this.downloadImage(this.urlList[this.index], this.nameList[this.index])
    },
    hide() {
      this.deviceSupportUninstall()
      this.onClose()
    },
    deviceSupportInstall() {
      this._keyDownHandler = e => {
        e.stopPropagation()
        const keyCode = e.keyCode
        switch (keyCode) {
          // ESC
          case 27:
            this.hide()
            break
          // SPACE
          case 32:
            this.toggleMode()
            break
          // LEFT_ARROW
          case 37:
            this.prev()
            break
          // UP_ARROW
          case 38:
            this.handleActions('zoomIn')
            break
          // RIGHT_ARROW
          case 39:
            this.next()
            break
          // DOWN_ARROW
          case 40:
            this.handleActions('zoomOut')
            break
        }
      }
      this._mouseWheelHandler = rafThrottle(e => {
        const delta = e.wheelDelta ? e.wheelDelta : -e.detail
        if (delta > 0) {
          this.handleActions('zoomIn', {
            zoomRate: 0.015,
            enableTransition: false
          })
        } else {
          this.handleActions('zoomOut', {
            zoomRate: 0.015,
            enableTransition: false
          })
        }
      })
      on(document, 'keydown', this._keyDownHandler)
      on(document, mousewheelEventName, this._mouseWheelHandler)
    },
    deviceSupportUninstall() {
      off(document, 'keydown', this._keyDownHandler)
      off(document, mousewheelEventName, this._mouseWheelHandler)
      this._keyDownHandler = null
      this._mouseWheelHandler = null
    },
    handleImgLoad(e) {
      this.loading = false
    },
    handleImgError(e) {
      this.loading = false
      e.target.alt = '加载失败'
    },
    handleMouseDown(e) {
      if (this.loading || e.button !== 0) return
      const { offsetX, offsetY } = this.transform
      const startX = e.pageX
      const startY = e.pageY
      this._dragHandler = rafThrottle(ev => {
        this.transform.offsetX = offsetX + ev.pageX - startX
        this.transform.offsetY = offsetY + ev.pageY - startY
      })
      on(document, 'mousemove', this._dragHandler)
      on(document, 'mouseup', ev => {
        off(document, 'mousemove', this._dragHandler)
      })
      e.preventDefault()
    },
    handleMaskClick() {
      if (this.maskClosable) {
        this.hide()
      }
    },
    reset() {
      this.transform = {
        scale: 1,
        deg: 0,
        offsetX: 0,
        offsetY: 0,
        enableTransition: false
      }
    },
    toggleMode() {
      if (this.loading) return
      const modeNames = Object.keys(Mode)
      const modeValues = Object.values(Mode)
      const index = modeValues.indexOf(this.mode)
      const nextIndex = (index + 1) % modeNames.length
      this.mode = Mode[modeNames[nextIndex]]
      this.reset()
    },
    handleClickSmallImg(imgIndex) {
      this.index = imgIndex
    },
    prev() {
      if (this.isFirst && !this.infinite) return
      const len = this.urlList.length
      this.index = (this.index - 1 + len) % len
    },
    next() {
      if (this.isLast && !this.infinite) return
      const len = this.urlList.length
      this.index = (this.index + 1) % len
    },
    handleActions(action, options = {}) {
      if (this.loading) return
      const { zoomRate, rotateDeg, enableTransition } = {
        zoomRate: 0.2,
        rotateDeg: 90,
        enableTransition: true,
        ...options
      }
      const { transform } = this
      switch (action) {
        case 'zoomOut':
          if (transform.scale > 0.2) {
            transform.scale = parseFloat((transform.scale - zoomRate).toFixed(3))
          }
          break
        case 'zoomIn':
          transform.scale = parseFloat((transform.scale + zoomRate).toFixed(3))
          break
        case 'clocelise':
          transform.deg += rotateDeg
          break
        case 'anticlocelise':
          transform.deg -= rotateDeg
          break
      }
      transform.enableTransition = enableTransition
    }
  },
  mounted() {
    this.deviceSupportInstall()
    if (this.appendToBody) {
      document.body.appendChild(this.$el)
    }
    // add tabindex then wrapper can be focusable via Javascript
    // focus wrapper so arrow key can't cause inner scroll behavior underneath
    this.$refs['el-image-viewer__wrapper'].focus()
  },
  destroyed() {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
  }
}
</script>
<style lang="scss" scoped>
  .img-name-big {
    position: fixed;
    width: 60%;
    text-align: center;
    font-size: 24px;
    color: #fff;
    margin: 0 20%;
    font-weight: bold;
    top: 50px;
    z-index: 1;
  }
  .el-image-viewer__actions {
    border-radius: 8px;
    background: #FFFFFF;
    bottom: 50px;
    width: 320px;
    height: 50px;
    padding: 0 26px;

    &__inner {
      color: #333333;
    }
  }
  .el-image-viewer__actions2 {
    bottom: 190px !important;
  }
  .el-image-viewer__btn {
    opacity: 1;
  }
  .btn_hover:hover {
    opacity: .8;
    cursor: pointer;
    scale: 1.1;
  }
  .el-image-viewer__fullScreen {
    top: 40px;
    right: 100px;
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #fff;
    background-color: #606266;
  }
  .img_scale {
    font-size: 14px;
  }
  .img_scale:hover {
    color: #333333;
  }
  .small_img {
    width: 120px;
    height: 120px;
  }
  .select_small_img {
    width: 108px !important;
    height: 108px !important;
    border: 6px solid #FF8F20;
  }

  /deep/ .small-img-box {
    bottom: 30px;
    position: absolute;
    margin: 0 12px;
    width: calc(100% - 24px);
    .el-tabs--card>.el-tabs__header {
      border-bottom: none;
      margin: 0;
    }
    .el-tabs--card>.el-tabs__header .el-tabs__nav {
      border: none;
    }
    .el-tabs--card>.el-tabs__header .el-tabs__item {
      border: none;
      line-height: unset;
      padding: 0 10px;
      height: auto;
    }
    .el-tabs__nav-wrap.is-scrollable {
      padding: 0px;
    }
    .el-tabs__nav-next {
      line-height: 120px;
      padding: 0 8px;
      font-size: 30px;
      z-index: 100;
      color: #fff;
      background: rgba(10, 10, 10, 0.48);

      .el-icon-arrow-right {
        font-weight: 900;
      }
    }
    .el-tabs__nav-prev {
      line-height: 120px;
      padding: 0 8px;
      font-size: 30px;
      z-index: 100;
      color: #fff;
      background: rgba(10, 10, 10, 0.48);

      .el-icon-arrow-left {
        font-weight: 900;
      }
    }
  }
</style>