import Layout from '@/page/index/'

/**
 * @author：cc@zdu56.com
 * 配置业务所有的路由
 */
const route = [
  {
    path: '/print',
    component: Layout,
    children: [
      {
        path: 'indentPrint',
        name: '打印',
        meta: {
        },
        component: () => import('@/views/print/indentPrint')
      }
    ]
  },
  {
    path: '/basic',
    component: Layout,
    children: [
      {
        path: 'tagManage',
        name: '标签管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/basic/tagManage')
      },
      {
        path: 'typeManage',
        name: '类型管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/basic/typeManage')
      },
      {
        path: 'goods',
        name: '常用货物',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/basic/goods')
      },
      {
        path: 'warehouse',
        name: '提货仓库',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/basic/warehouse')
      },
      {
        path: 'inspect',
        name: '核查点管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/basic/inspect')
      },
      {
        path: 'insuranceFeeRate',
        name: '保险费率配置',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/basic/insuranceFeeRate')
      },
      {
        path: 'insuranceService',
        name: '保险服务',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/basic/insuranceService')
      },
      {
        path: 'vatServices',
        name: '增值服务',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/basic/vatServices')
      },
      {
        path: 'address',
        name: '常用地址',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/basic/address')
      },
      {
        path: 'lineCombine',
        name: '常用线路',
        meta: {
          keepAlive: false,
          $keepAlive: false,
          isAuth: true
        },
        component: () => import('@/views/basic/lineCombine')
      }
    ]
  },
  {
    path: '/platform',
    component: Layout,
    children: [
      {
        path: 'platformCar',
        name: '载具',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/platform/platformCar')
      },
      {
        path: 'platformDriver',
        name: '驾驶员',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/platform/platformDriver')
      },
      {
        path: 'receiveInfo',
        name: '收款信息',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/platform/receiveInfo')
      },
      {
        path: 'transportUpload',
        name: '运力上传',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/platform/transportUpload')
      },
      {
        path: 'motorcadeList',
        name: '车队列表',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/platform/motorcadeList')
      },
      {
        path: 'vehiclePoolMap',
        name: '运力池',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/platform/carHeatmap/vehiclePoolMap')
      },
      {
        path: 'vehiclePosition',
        name: '车辆位置',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/platform/carHeatmap/vehicleWaybillMap')
      },
      {
        path: 'vehicleLocationAndTrajectory',
        name: '车辆位置/轨迹',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/platform/vehicleLocationAndTrajectory')
      }
    ]
  },
  {
    path: '/goodsSupply', // 货源
    component: Layout,
    children: [
      {
        path: 'newPlanList',
        name: '新计划管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/plan/newPlanList')
      },
      {
        path: 'planList',
        name: '计划管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/plan/planList')
      },
      {
        path: 'planAdd',
        name: '新增计划',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/plan/planAdd')
      },
      {
        path: 'planEdit/:id',
        name: '编辑计划',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/plan/planAdd')
      },
      {
        path: 'publishGoodsSupply',
        name: '发布货源',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/publishGoodsSupply')
      },
      {
        path: 'publishGoodsSupply/:id',
        name: '发布货源',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/publishGoodsSupply')
      },
      {
        path: 'rePublishGoodsSupply',
        name: '重新发布货源',
        meta: {
          keepAlive: false,
          $keepAlive: false,
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/rePublishGoodsSupply')
      },
      {
        path: 'goodsSupplyList',
        name: '货源池',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/goodsSupplyList')
      },
      {
        path: 'goodsSupplyDetail/:id',
        name: '货源详情',
        meta: {
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/goodsSupplyDetail')
      },
      {
        path: 'planDetail/:planNo',
        name: '计划详情',
        meta: {
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/planDetail')
      },
      {
        path: 'planDetailNew/:planId',
        name: '计划详情2',
        meta: {
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/planDetailNew')
      },
      {
        path: 'planImportList',
        name: '计划导入',
        meta: {
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/plan/planImportList')
      },
      {
        path: 'addPlanOrder',
        name: '新建订单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/plan/operate')
      },
      {
        path: 'editPlanOrder/:id',
        name: '编辑订单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsSupply/plan/operate')
      }
    ]
  },
  {
    path: '/orderManager', // 订单管理
    component: Layout,
    children: [
      {
        path: 'myOrder',
        name: '订单管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/myOrder')
      },
      {
        path: 'orderImport',
        name: '批量导入',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/myOrder/orderImport')
      },
      {
        path: 'buildOrder',
        name: '新建订单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/buildOrder')
      },
      {
        path: 'planToOrder/:planId',
        name: '计划下单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/planToOrder')
      },
      {
        path: 'editOrder/:id',
        name: '编辑订单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/editOrder')
      },
      {
        path: 'orderAcceptance/:id',
        name: '受理订单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/orderAcceptance')
      },
      {
        path: 'lclOrderAcceptance/:id',
        name: '零担订单受理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/lclOrderAcceptance')
      },
      {
        path: 'orderDetail/:id',
        name: '订单详情',
        meta: {
          keepAlive: false,
          $keepAlive: false,
          isAuth: true
        },
        component: () => import('@/views/orderManager/orderDetail')
      },
      {
        path: 'lclOrderDetail/:id',
        name: '零担订单详情',
        meta: {
          keepAlive: false,
          $keepAlive: false,
          isAuth: true
        },
        component: () => import('@/views/orderManager/lclOrderDetail')
      },
      {
        path: 'lclOrderEdit/:id',
        name: '零担订单编辑',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/lclOrderEdit')
      },
      {
        path: 'splitOrderByQuantity',
        name: '订单按量拆分',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/splitOrderByQuantity')
      },
      {
        path: 'splitOrderByPiece',
        name: '订单按段拆分',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/splitOrderByPiece')
      },
      {
        path: 'splitOrder',
        name: '拆单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/splitOrder')
      },
      {
        path: 'subcontract/:id',
        name: '订单转包',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/subcontract')
      },
      {
        path: 'addFiscalEntrust',
        name: '财税建单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/fiscalEntrust/index')
      },
      {
        path: 'fiscalEntrustDetail/:id',
        name: '财税建单详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/fiscalEntrust/detail')
      },
      {
        path: 'settlementAdd',
        name: '结算建单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/settlement/add')
      },
      {
        path: 'settlementDetail/:id',
        name: '结算详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/settlement/detail')
      },
      {
        path: 'queckOrder',
        name: '下单派车',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/orderManager/queckOrder/index')
      }
    ]
  },
  {
    path: '/waybill', // 运单管理
    component: Layout,
    children: [
      {
        path: 'waybillList',
        name: '运单管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/waybill/waybillList')
      },
      {
        path: 'waybillDetail/:no',
        name: '运单详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/waybill/waybillDetail')
      }
    ]
  },
  {
    path: '/goodsCodePromotion', // 聚货码推广
    component: Layout,
    children: [
      {
        path: 'setting',
        name: '推广设置',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsCodePromotion/setting')
      },
      {
        path: 'settlement',
        name: '推广结算',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsCodePromotion/settlement')
      },
      {
        path: 'person',
        name: '推广人员',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsCodePromotion/person')
      },
      {
        path: 'order',
        name: '推广订单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsCodePromotion/order')
      },
      {
        path: 'report',
        name: '推广报表',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/goodsCodePromotion/report')
      }
    ]
  },
  {
    path: '/company', // 公司管理
    component: Layout,
    children: [
      {
        path: 'operationLog',
        name: '操作日志',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/company/operationLog')
      },
      {
        path: 'employee',
        name: '员工管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/company/employee')
      },
      {
        path: 'informationDetail',
        name: '企业详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/company/informationDetail')
      },
      {
        path: 'enterpriseVali',
        name: '企业认证',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/company/enterpriseVali')
      },
      {
        path: 'websiteConfig',
        name: '官网配置',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/company/websiteConfig')
      },
      {
        path: 'department',
        name: '部门管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/company/department')
      },
      {
        path: 'rolemanages',
        name: '角色管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/company/rolemanages')
      }
    ]
  },
  {
    path: '/partner', // 合作伙伴
    component: Layout,
    children: [
      {
        path: 'shipperPartnerList',
        name: '托运合作伙伴',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/partner/shipper/index')
      },
      {
        path: 'carrierPartnerList',
        name: '承运合作伙伴',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/partner/carrier/index')
      },
      {
        path: 'myFreightPlatform',
        name: '我的开票平台',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/partner/myFreightPlatform/index')
      },
      {
        path: 'enterpriseAudit',
        name: '企业审核',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/partner/enterpriseAudit')
      }
    ]
  },
  {
    path: '/finance', // 财务管理
    component: Layout,
    children: [
      {
        path: 'hubProfit',
        name: '应收平台账单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/hubProfit')
      },
      {
        path: 'orderContractPrice',
        name: '订单合同价',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/orderContractPrice')
      },
      {
        path: 'bankRegister',
        name: '运输商户',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/bankRegister')
      },
      {
        path: 'courseManagement',
        name: '科目管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/courseManagement')
      },
      {
        path: 'voucherTemplates',
        name: '凭证模板',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/voucherTemplates')
      },
      {
        path: 'voucherManagement',
        name: '凭证管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/voucherManagement')
      },
      {
        path: 'voucherPrint',
        name: '凭证打印',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/voucherManagement/voucherPrint')
      },
      {
        path: 'addTemplates',
        name: '新建模板',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/voucherTemplates/components/addTemplates')
      },
      {
        path: 'editTemplates',
        name: '编辑模板',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/voucherTemplates/components/addTemplates')
      },
      {
        path: 'imprestList',
        name: '付款单管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/imprestList')
      },
      // {
      //   path: 'imprestDetail',
      //   name: '付款单详情',
      //   meta: {
      //     keepAlive: true,
      //     $keepAlive: true,
      //     isAuth: true
      //   },
      //   component: () =>
      //     import('@/views/finance/imprestList/components/imprestDetail')
      // },
      {
        path: 'reciveAndPay',
        name: '应收应付账单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/reciveAndPay')
      },
      {
        path: 'generateVoucher',
        name: '生成凭证',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/generateVoucher')
      },
      {
        path: 'generateVoucherByOrder/:id',
        name: '生成凭证',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/generateVoucher')
      },
      {
        path: 'voucher/:id',
        name: '凭证详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/voucherDetails')
      },
      {
        path: 'voucherEdit/:id',
        name: '凭证编辑',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/voucherDetails')
      },
      {
        path: 'configPercent9',
        name: '财务配置（9%）',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/configPercent9')
      },
      {
        path: 'profitPrint/:id',
        name: '结算单打印',
        meta: {
          keepAlive: false,
          $keepAlive: false,
          isAuth: true
        },
        component: () => import('@/views/finance/reciveAndPay/profitPrint')
      },
      {
        path: '3invoiceApply',
        name: '司机代开',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/threeInvoicing/apply')
      },
      {
        path: 'eleReceipt',
        name: '电子回单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/eleReceipt')
      },
      {
        path: 'paymentRegistrationDetail',
        name: '收支登记明细',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/paymentRegistrationDetail')
      },
      {
        path: 'paymentRegistration',
        name: '收支登记',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/paymentRegistration')
      },
      {
        path: 'financialFoundation',
        name: '财务基础设置',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/financialFoundation')
      },
      {
        path: 'capitalFlowRecord',
        name: '资金流水单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/finance/capitalFlowRecord')
      }
    ]
  },
  {
    path: '/wallet', // 消费钱包
    component: Layout,
    children: [
      {
        path: 'consumerAccount',
        name: '账户总览',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/wallet/consumerAccount')
      },
      {
        path: 'accountFlow',
        name: '消费账户流水',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/wallet/accountFlow')
      },
      {
        path: 'rechargWithdrawRecord',
        name: '充值提现记录',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/wallet/rechargWithdrawRecord')
      }
    ]
  },
  {
    path: '/vatService', // 增值服务
    component: Layout,
    children: [
      {
        path: 'insuranceList',
        name: '保险清单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vatService/insuranceList')
      },
      {
        path: 'insuranceListNew',
        name: '保险清单2',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vatService/insuranceListNew')
      },
      {
        path: 'contractList',
        name: '合同清单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vatService/contractList')
      },
      {
        path: 'thirdTrajectory',
        name: '第三方轨迹',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vatService/thirdTrajectory')
      }
    ]
  },
  {
    path: '/quality', // 质量管理
    component: Layout,
    children: [
      {
        path: 'evaluation',
        name: '评价管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/quality/evaluationManage')
      },
      {
        path: 'feedback',
        name: '投诉咨询',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/quality/feedback')
      }
    ]
  },
  {
    path: '/wlhyPush', // 监测推送
    component: Layout,
    children: [
      {
        path: 'pushSetting',
        name: '推送设置',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/wlhyPush/pushSetting')
      },
      {
        path: 'monitoringPush',
        name: '监测推送',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/wlhyPush/monitoringPush')
      },
      {
        path: 'pushRecord',
        name: '推送记录',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/wlhyPush/pushRecord')
      }
    ]
  },
  {
    path: '/configSetting', // 参数设置
    component: Layout,
    children: [
      {
        path: 'settingIndex',
        name: '参数设置',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/configSetting/settingIndex')
      },
      {
        path: 'repalceText',
        name: '自定义字段',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/configSetting/repalceText')
      }
    ]
  },
  {
    path: '/etcInvoice', // ect开票
    component: Layout,
    children: [
      {
        path: 'enterpriseAuth',
        name: '开票企业认证',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/etcInvoice/enterpriseAuth')
      },
      {
        path: 'etcInvoiceRecord',
        name: 'ETC开票记录',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/etcInvoice/etcInvoiceRecord')
      }
    ]
  },
  {
    path: '/onlineStore', // 在线商城
    component: Layout,
    children: [
      {
        path: 'mallOrders',
        name: '商城订单',
        meta: {
          keppAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/onlineStore/mallOrders')
      },
      {
        path: 'addValueServices',
        name: '增值服务',
        meta: {
          keppAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/onlineStore/addValueServices')
      },
      {
        path: 'payForOrder/:id',
        name: '订单支付',
        meta: {
          keppAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/onlineStore/payForOrder')
      },
      {
        path: 'mallLists',
        name: '商品列表',
        meta: {
          keppAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/onlineStore/mallLists')
      },
      {
        path: 'mallDetail/:id',
        name: '商品详情',
        meta: {
          keppAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/onlineStore/mallDetail')
      }
    ]
  },
  {
    path: '/traceability', // 溯源
    component: Layout,
    children: [
      {
        path: 'process',
        name: '过程节点管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/traceability/process')
      },
      {
        path: 'cargoCode',
        name: '货物码管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/traceability/cargoCode')
      },
      {
        path: 'action',
        name: '作业动作管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/traceability/action')
      },
      {
        path: 'processData',
        name: '过程数据管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/traceability/processData')
      },
      {
        path: 'codeRelationship',
        name: '溯源码父子关系',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/traceability/codeRelationship')
      }
    ]
  },
  { // 运营管理
    path: '/operationsManagement',
    name: '运营管理',
    component: Layout,
    children: [
      {
        path: 'orderAcceptance',
        name: '托运单受理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/orderAcceptance')
      },
      {
        path: 'scanDispatch',
        name: '扫码调度',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/scanDispatch/list')
      },
      {
        path: 'addScanDispatch',
        name: '新建扫码调度',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/scanDispatch/operate')
      },
      {
        path: 'editScanDispatch/:id',
        name: '编辑扫码调度',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/scanDispatch/operate')
      },
      {
        path: 'addNewScanDispatch/:id',
        name: '新建扫码调度',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/scanDispatch/operate')
      },
      {
        path: 'scanDispatchDetail/:id',
        name: '扫码调度详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/scanDispatch/detail')
      },
      {
        path: 'selfDispatch',
        name: '自助调度',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/selfDispatch')
      },
      {
        path: 'selfDispatchNew',
        name: '自助调度2',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/selfDispatchNew')
      },
      {
        path: 'orderAcceptanceAdd/:id',
        name: '托运单编辑',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/orderAcceptance/add')
      },
      {
        path: 'orderAcceptanceAdd2/:id',
        name: '托运单详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/orderAcceptance/add')
      },
      {
        path: 'inventoryManagement',
        name: '库存管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/inventoryManagement')
      },
      {
        path: 'inventoryManagementNew',
        name: '库存管理2',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/inventoryManagementNew')
      },
      {
        path: 'pickUpGoods',
        name: '提货管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/pickUpGoods')
      },
      {
        path: 'createdPickUp',
        name: '添加提货单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/createdPickUp')
      },
      {
        path: 'createdDelivery',
        name: '添加送货单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/createdDelivery')
      },
      {
        path: 'deliveryDetail/:deliveryid',
        name: '修改送货单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/deliveryDetail')
      },
      {
        path: 'receiptManagement',
        name: '签收管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/receiptManagement')
      },
      {
        path: 'receiptManagementNew',
        name: '签收管理2',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/receiptManagementNew')
      },
      {
        path: 'order',
        name: '订单管理2',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/order/list')
      },
      {
        path: 'orderImport',
        name: '订单导入',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/order/import')
      },
      {
        path: 'addOrder',
        name: '新建订单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/order/operate')
      },
      {
        path: 'addNewOrder/:id',
        name: '再建一单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/order/operate')
      },
      {
        path: 'editOrder/:id',
        name: '编辑订单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/order/operate')
      },
      {
        path: 'acceptOrder/:id',
        name: '受理订单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/order/operate')
      },
      {
        path: 'orderDetail/:id',
        name: '订单详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/order/detail')
      },
      {
        path: 'dispatch',
        name: '调度管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/dispatch/list')
      },
      {
        path: 'scheduledTask',
        name: '调度任务',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/scheduledTask/list')
      },
      {
        path: 'waybill',
        name: '运单管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/waybill/list')
      },
      {
        path: 'waybillDetail/:id',
        name: '运单详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/waybill/detail')
      },
      {
        path: 'addScheduledTask',
        name: '新建调度任务',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/scheduledTask/operate')
      },
      {
        path: 'editScheduledTask/:id',
        name: '编辑调度任务',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/scheduledTask/operate')
      },
      {
        path: 'scheduledTaskDetail/:id',
        name: '调度任务详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/scheduledTask/detail')
      },
      {
        path: 'buildDispatch',
        name: '新建调度',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/dispatch/buildDispatch')
      },
      {
        path: 'dispatchDetail/:id',
        name: '调度详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/dispatch/dispatchDetail')
      },
      {
        path: 'deliveryGoods',
        name: '送货管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/deliveryGoods')
      },
      {
        path: 'editPickUp/:editpickupid',
        name: '修改提货单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/editPickUp')
      },
      {
        path: 'exceptionManagement',
        name: '异常管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/exceptionManagement')
      },
      {
        path: 'recycleList',
        name: '回单回收',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/receipt/recycleList')
      },
      {
        path: 'giveOutList',
        name: '回单发放',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/receipt/giveOutList')
      },
      {
        path: 'takeList',
        name: '回单接收',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/receipt/takeList')
      },
      {
        path: 'sendList',
        name: '回单寄出',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/receipt/sendList')
      },
      {
        path: 'receipt',
        name: '回单管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/receipt')
      },
      {
        path: 'arriveCar',
        name: '到车管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/arriveCar/list')
      },
      {
        path: 'arriveCarDetail/:id',
        name: '到车详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/arriveCar/detail')
      },
      {
        path: 'arriveCarUnloading/:id',
        name: '卸车入库/签收',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/arriveCar/unloadingSign')
      },
      {
        path: 'transferOutManagement',
        name: '转出管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/transferOutManagement')
      },
      {
        path: 'transferInManagement',
        name: '转入管理',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/transferInManagement')
      },
      {
        path: 'createTransfer',
        name: '新建转包',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/transferOutManagement/createTransfer')
      },
      {
        path: 'editTransfer/:id',
        name: '编辑转出',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/transferOutManagement/createTransfer')
      },
      {
        path: 'detailTransfer/:id',
        name: '转出详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/transferOutManagement/detailTransfer')
      },
      {
        path: 'transferDetail/:id',
        name: '转入详情',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/transferInManagement/transferDetail')
      },
      {
        path: 'transferInAccept/:id',
        name: '受理转入',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/operationsManagement/transferInManagement/transferInAccept')
      }
    ]
  },
  {
    path: '/statementCenter',
    component: Layout,
    name: '报表中心',
    children: [
      {
        path: 'grossProfitStatistics',
        name: '单票毛利统计',
        meta: {
          keppAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/statementCenter/grossProfitStatistics')
      },
      {
        path: 'dataLedger',
        name: '数据台账',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/statementCenter/dataLedger')
      },
      {
        path: 'dispatchShipOrderList',
        name: '发车托运单明细',
        meta: {
          keppAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/statementCenter/dispatchShipOrderList')
      },
      {
        path: 'deliverShipOderList',
        name: '送货托运单明细',
        meta: {
          keppAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/statementCenter/deliverShipOderList')
      },
      {
        path: 'reportCard',
        name: '数据看板',
        meta: {
          keppAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/statementCenter/reportCard')
      },
      {
        path: 'reportDetail',
        name: '客户数据明细',
        meta: {
          keppAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/statementCenter/reportDetail')
      },
      {
        path: 'exportDownLoad',
        name: '数据导出下载',
        meta: {
          keppAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/statementCenter/exportDownLoad')
      },
      {
        path: 'financeData',
        name: '财务数据统计',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/statementCenter/financeData')
      },
      {
        path: 'outCar',
        name: '出车统计表',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/statementCenter/outCar')
      }
    ]
  },
  {
    path: '/message', // 消息中心
    component: Layout,
    children: [
      {
        path: 'index',
        name: '消息中心',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/message/index')
      }
    ]
  },
  {
    path: '/allianceManagement', // 联盟管理
    component: Layout,
    name: '联盟管理',
    children: [
      {
        path: 'foundingAlliance',
        name: '创始联盟',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/allianceManagement/foundingAlliance')
      },
      {
        path: 'allianceEnterprise',
        name: '创始联盟企业',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/allianceManagement/allianceEnterprise')
      },
      {
        path: 'createOrder',
        name: '新建订单',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/allianceManagement/createOrder')
      },
      {
        path: 'allianceApply',
        name: '创始联盟申请',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/allianceManagement/allianceApply')
      }
    ]
  },
  {
    path: '/vehicleNet', // 车联网管理
    component: Layout,
    name: '车联网管理',
    children: [
      {
        path: 'prd',
        name: '监控数据中心',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vehicleNet/prd')
      },
      {
        path: 'trackRender',
        name: '历史轨迹',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vehicleNet/trackRender')
      },
      {
        path: 'realTimeVideo',
        name: '实时视频',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vehicleNet/realTimeVideo')
      },
      {
        path: 'historicalVideoRoute',
        name: '历史视频',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vehicleNet/historicalVideoRoute')
      },
      {
        path: 'vehicleMonitorPloy',
        name: '车辆监控',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vehicleNet/vehicleMonitorPloy')
      },
      {
        path: 'imageCapture',
        name: '图像抓拍',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vehicleNet/imageCapture')
      },
      {
        path: 'mileageStatistics',
        name: '里程统计',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vehicleNet/mileageStatistics')
      },
      {
        path: 'vehicleDashboard',
        name: '车辆运营看板',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vehicleNet/vehicleDashboard')
      },
      {
        path: 'alarmQuery',
        name: '报警统计',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vehicleNet/alarmQuery')
      },
      {
        path: 'driveBehavior',
        name: '驾驶行为统计',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vehicleNet/driveBehavior')
      },
      {
        path: 'oilGas',
        name: '能耗统计',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/vehicleNet/oilGas')
      }
    ]
  },
  {
    path: '/smart', // 智能录单配置
    component: Layout,
    children: [
      {
        path: 'buildOrder',
        name: '智能录单配置',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/smartBuildOrder/index')
      }
    ]
  },
  {
    path: '/importExportTemplates', // 导入导出模板
    component: Layout,
    children: [
      {
        path: 'index',
        name: '导入导出模板',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import('@/views/importExportTemplates/index')
      }
    ]
  }
  // {
  //   path: '/绑定手机号',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       name: '绑定手机号',
  //       meta: {
  //         keepAlive: true,
  //         $keepAlive: true,
  //         isAuth: true
  //       },
  //       component: () => import('@/views/bindMobile/bindMobile')
  //     }
  //   ]
  // }
]

export default route
